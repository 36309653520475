export const productCampaingSegments = [
  'supertarjous',
  'superhinta',
  'aina edullinen',
  'outlet',
  'tarjous',
  'uutuus'
]

export const priceLabels = ['klubitarjous', 'supertarjous', 'aina edullinen', 'superhinta', 'outlet']
