import { GetterTree, ActionTree, MutationTree, Commit } from 'vuex'
import type { Swatch, State, SwatchStyles, SwatchType, SwatchNameWithStyles } from '~/types/swatch'
import { RootState } from '~/store'

const allowedSwatchTypes: SwatchType[] = ['Petauspatja', 'Jalat', 'Väri', 'Väriryhmä', 'Verhoilu', 'Rungon väri', 'Jäykkyys', 'Kätisyys', 'Vedin', 'Laatikoston tyyppi']

export const state = () => ({
  swatches: [] as Swatch[],
  fetching: [] as SwatchType[]
})

export type SwatchState = State

export enum SwatchGetters {
  getSwatchesByAttribute = 'swatch/getSwatchesByAttribute',
  getSwatch = 'swatch/getSwatch',
  getSwatchStyles = 'swatch/getSwatchStyles',
  getManySwatchStyles = 'swatch/getManySwatchStyles'
}
export enum SwatchActions {
  fetchSwatchesByType = 'swatch/fetchSwatchesByType'
}
export enum SwatchMutations {
  setSwatches = 'swatch/SET_SWATCHES',
  startFetching = 'swatch/START_FETCHING',
  stopFetching = 'swatch/STOP_FETCHING'
}

export const mutations: MutationTree<SwatchState> = {
  SET_SWATCHES (state: SwatchState, payload: Swatch[]) {
    state.swatches.push(...payload)
  },
  START_FETCHING (state: SwatchState, type: SwatchType) {
    state.fetching.push(type)
  },
  STOP_FETCHING (state: SwatchState, type: SwatchType) {
    state.fetching = state.fetching.filter(t => t !== type)
  }
}

export const getters: GetterTree<SwatchState, RootState> = {
  getSwatchesByAttribute: (state: State) => (type: string): Swatch[] => state.swatches.filter(sw => sw.type === type) || [],
  getSwatch: (_state: State, getters: any) => (type: string, name: string): Swatch|null => getters.getSwatchesByAttribute(type).find((c: Swatch) => c.name?.toLowerCase() === name?.toLowerCase()) || null,
  getManySwatchStyles: (_state: State, getters: any) => (type: string, names: string[], imageSize: 'thumbnail'|'small'|'full' = 'thumbnail'): SwatchNameWithStyles[] => names.map(name => ({ name, styles: getters.getSwatchStyles(type, name, imageSize) })),
  getSwatchStyles: (state: State, getters: any) => (type: string, name: string, imageSize: 'thumbnail'|'small'|'full' = 'thumbnail'): SwatchStyles => {
    const swatch = getters.getSwatch(type, name)
    if (!swatch) {
      if (process.env.NODE_ENV === 'development' && state.swatches.some(sw => sw.type === type)) {
        console.warn(`Swatch missing: %c${name}`, 'font-weight: bold', `(${type}) - Add one to Contentful!`)
      }
      return ({
        backgroundImage: 'radial-gradient(circle, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 31%, rgba(255,255,255,1) 31%, rgba(255,255,255,1) 39%, rgba(245,245,245,1) 39%, rgba(245,245,245,1) 100%)'
      })
    }
    const imageSizes = {
      thumbnail: 'imageThumbnail',
      small: 'imageSmall',
      full: 'imageFull'
    }
    const image = swatch[imageSizes[imageSize]]

    const bgImgs: string[] = []

    if (image?.url) {
      bgImgs.push("url('" + image.url + "')")
    }
    if (swatch.gradient) {
      bgImgs.push(swatch.gradient)
    } else if (swatch.secondaryColor) {
      bgImgs.push(`linear-gradient(90deg, ${swatch.primaryColor} 50%, ${swatch.secondaryColor} 50%, ${swatch.secondaryColor} 100%)`)
    }

    const styles: SwatchStyles = {
      backgroundColor: swatch.primaryColor
    }
    if (bgImgs.length) {
      styles.backgroundImage = bgImgs.join(',')
      styles.backgroundSize = 'cover'
      styles.backgroundPosition = 'center'
    }
    if (swatch.showBorder) {
      styles.borderWidth = '1px'
    }
    if (swatch.type === 'Väriryhmä') {
      styles.color = swatch.primaryColor
    }
    return styles
  }
}

export const actions: ActionTree<SwatchState, RootState> = {
  async fetchSwatchesByType ({ state, commit }: { state: SwatchState, commit: Commit }, type: SwatchType): Promise<void> {
    if (!allowedSwatchTypes.includes(type) || state.fetching.includes(type) || state.swatches.some(sw => sw.type === type)) { return }
    commit('START_FETCHING', type)
    // @ts-ignore
    await this.$contentDb.getSwatchesByType(type)
      .then((swatches: any) => {
        // Remove null properties from swatches to make the list smaller
        // @ts-ignore
        const minified = swatches.map((s: any) => Object.entries(s).reduce((a, [k, v]) => (v == null ? a : ((a[k]) = v, a)), {}))
        commit('SET_SWATCHES', minified)
      })
      .catch((error: any) => {
        // @TODO: Add Sentry logging
        console.error(error)
      })
    commit('STOP_FETCHING', type)
  }
}
