
import algoliasearch from 'algoliasearch/lite'
// @ts-ignore
import { AisConfigure, AisInstantSearch, AisHits, AisIndex } from 'vue-instantsearch'
// @ts-ignore
import debounce from 'lodash.debounce'
import { defineComponent, ref, Ref, useRouter, useRoute, useContext, useStore, computed } from '@nuxtjs/composition-api'
import SearchField from '~/components/SearchField.vue'
import SearchBox from '~/components/vue-instantsearch/SearchBox.vue'
import { XIcon } from '~/components/veke-ui/icons'
import SearchItemProduct from '~/components/search/SearchItemProduct.vue'
import SearchItemLink from '~/components/search/SearchItemLink.vue'
import { CategoryGetters } from '~/store/category'
import { UserGetters } from '~/store/user'
import { attributesForProductTile } from '~/helpers/instantsearch'
import { useTime } from '~/composable/useTime'
import type { CategoryItem } from '~/types/category'
import { useScrollHeader } from '~/composable/useScrollHeader'
type RefineFunction = (value: string) => void;

export default defineComponent({
  name: 'SearchPanel',
  components: {
    SearchField, SearchItemProduct, SearchItemLink, AisConfigure, AisInstantSearch, SearchBox, AisHits, AisIndex
  },
  setup () {
    const query = ref('')
    const searchQuery = ref('')
    const router = useRouter()
    const route = useRoute()
    const context = useContext()
    const productIndexName = context.$config.ALGOLIA_INDEX
    const categoryIndexName = context.$config.ALGOLIA_CATEGORY_INDEX
    const contentsIndexName = context.$config.ALGOLIA_CONTENTS_INDEX
    const blogsIndexName = context.$config.ALGOLIA_BLOGS_INDEX
    const algoliaClient = algoliasearch(context.$config.ALGOLIA_APP_ID, context.$config.ALGOLIA_API_KEY)
    const { isVisible } = useScrollHeader()
    const searchClient = {
      search (requests: any) {
        if (requests.some(({ params: { query } }: any) => query !== '')) {
          return algoliaClient.search(requests)
        }
        return Promise.resolve({ results: [{ hits: [] }] })
      },
      searchForFacetValues: algoliaClient.searchForFacetValues
    }
    const isSuggestionsOpen = ref(false)
    const closeSuggestions = (blurInput = true) => {
      isSuggestionsOpen.value = false
      if (blurInput && searchinput.value) {
        searchinput.value?.$el.querySelector('input').blur()
      }
    }
    const toSearchPage = () => {
      if (query.value) {
        const queryText = query.value + ''
        if (route.value.name === 'haku') {
          if (route.value.query?.q !== queryText) {
            router.push({ path: '/haku', query: { q: queryText, jarjesta: route.value.query?.jarjesta } })
          }
          if (process.client) {
            window.scrollTo({ top: 0, left: 0 })
          }
        } else {
          router.push({ path: '/haku' })
          // Ais uiState from the previous page (category) is cleared at this point, clearing the search query as well
          // Add search query to URL with a small delay
          setTimeout(() => {
            router.replace({ path: '/haku', query: { q: queryText } })
          }, 300)
        }

        closeSuggestions()
      }
    }
    const clearQuery = () => {
      closeSuggestions()
      query.value = ''
      searchQuery.value = ''
    }
    const updateSearch = debounce(function (refine: RefineFunction, val: string) { isSuggestionsOpen.value = true; searchQuery.value = val; refine(val) }, 150)
    const store = useStore()
    const searchinput: Ref<any> = ref(null)

    const namesOfMainCategories = store.getters[CategoryGetters.getMainCategories].map((cat: CategoryItem) => cat.name)

    const categoryItems = (items: any) => items.map((item: { breadcrumb?: string, name: string, objectID: string, path: string }) => {
      let parentCategory = null
      if (namesOfMainCategories.includes(item.name)) {
        parentCategory = 'Tuotteet'
      } else if (item.breadcrumb) {
        const crumbs = item.breadcrumb?.split('>') || []
        parentCategory = crumbs.length > 1 ? crumbs.slice(-2)[0] : null
      }
      return {
        ...item,
        parentCategory
      }
    })

    const parseDate = (date: string) => {
      const { formatISOString } = useTime()
      return formatISOString(date, 'd.M.yyyy')
    }

    const suggestionsVisible = computed(() => query.value.length && searchQuery.value.length > 1 && isSuggestionsOpen.value)

    const eventHandler = (item: any) => {
      /**
       * Algolia Insights call causes an CORB-warning in Chrome console:
       * https://support.algolia.com/hc/en-us/articles/4418789171601-I-am-seeing-a-Cross-Origin-Read-Blocking-error-CORB-error-in-the-console-when-using-Google-Chrome-what-does-this-mean-
       * https://github.com/algolia/search-insights.js/issues/253#issuecomment-842152675
       */
      context.$algoliaInsights('clickedObjectIDsAfterSearch', {
        userToken: store.getters[UserGetters.loggedIn] ? 'logged_in_user' : 'guest_user',
        index: context.$config.ALGOLIA_INDEX,
        eventName: 'product_click_after_search_bar_search',
        queryID: item.__queryID,
        objectIDs: [item.objectID],
        positions: [item.__position]
      })
    }

    return { isVisible, searchinput, XIcon, isSuggestionsOpen, closeSuggestions, suggestionsVisible, searchQuery, query, updateSearch, toSearchPage, clearQuery, productIndexName, categoryIndexName, contentsIndexName, blogsIndexName, searchClient, eventHandler, attributesForProductTile, categoryItems, parseDate }
  }
})
