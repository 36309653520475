import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { useShipping } from '~/composable/useShipping'
import { CartGetters } from '~/store/cart'
import type { PaymentCart } from '~/types/paymentGateway'
import type { DeliveryMethod, DeliveryMethodGroup, DeliveryMethodGroupWithMethods, PaymentMethodGroup, PaymentMethod, PaymentMethodGroupWithOptions, CheckoutStepIndex, PartPaymentPlan, PartPaymentPlanCampaign, CustomerInfo, UserInfo } from '~/types/checkout'
import type { CartAddress } from '~/types/cart'
import type { UserAddress } from '~/types/user'

const getClearAddress = (): CartAddress => ({
  firstname: '',
  lastname: '',
  phone: '',
  street1: '',
  postcode: '',
  city: '',
  countryCode: 'FI' as 'FI'
})
const getClearState = () => ({
  allDeliveryMethodGroups: require('~/config/delivery-method-groups.json') as DeliveryMethodGroup[],
  allDeliveryMethods: require('~/config/delivery-methods.json') as DeliveryMethod[],
  selectedDeliveryMethod: null as DeliveryMethod | null,
  allPaymentMethodGroups: require('~/config/payment-method-groups.json') as PaymentMethodGroup[],
  availablePaymentMethods: [] as PaymentMethod[],
  selectedPaymentMethod: null as PaymentMethod | null,
  activeCheckoutStepIndex: 0 as CheckoutStepIndex,
  isFetchingPaymentMethods: false,
  hasPaymentMethodFetchFailed: false,
  paymentMethodsFetchedWithSum: null as number | null,
  hasSeparateShippingAddress: false,
  saveAsDefault: false,
  partPaymentPlan: null as PartPaymentPlan | null,
  user: {
    firstname: '',
    lastname: '',
    phone: '',
    email: ''
  } as UserInfo,
  addresses: {
    billing: getClearAddress(),
    shipping: getClearAddress()
  },
  comment: '',
  partialDelivery: false,
  subscribeNewsletter: false
})

export const state = () => getClearState()

export type CheckoutState = ReturnType<typeof state>

export enum CheckoutActions {
  selectPaymentMethod = 'checkout/selectPaymentMethod',
  clearSelectedDeliveryMethod = 'checkout/clearSelectedDeliveryMethod',
  clearSelectedPaymentMethod = 'checkout/clearSelectedPaymentMethod',
  clearComment = 'checkout/clearComment',
  fetchPaymentMethods = 'checkout/fetchPaymentMethods',
  activateStep = 'checkout/activateStep',
  setAddressData = 'checkout/setAddressData',
  hasSeparateShippingAddress = 'checkout/hasSeparateShippingAddress',
  selectDeliveryMethod = 'checkout/selectDeliveryMethod',
  validateSelectedPaymentMethod = 'checkout/validateSelectedPaymentMethod',
  validateSelectedDeliveryMethod = 'checkout/validateSelectedDeliveryMethod',
  payOrder = 'checkout/payOrder',
  clearCheckout = 'checkout/clearCheckout',
  selectOnlyPaymentMethod = 'checkout/selectOnlyPaymentMethod',
  fetchPartPaymentPlanForAmount = 'checkout/fetchPartPaymentPlanForAmount',
  prefillUserAddresses = 'checkout/prefillUserAddresses',
  saveCustomerInfo = 'checkout/saveCustomerInfo',
  setPartialDelivery = 'checkout/setPartialDelivery',
  subscribeNewsletter = 'checkout/subscribeNewsletter'
}

export enum CheckoutMutations {
  setSelectedDeliveryMethod = 'checkout/SET_SELECTED_DELIVERY_METHOD',
  setSelectedPaymentMethod = 'checkout/SET_SELECTED_PAYMENT_METHOD',
  setPaymentMethods = 'checkout/SET_PAYMENT_METHODS',
  setActiveCheckoutStepIndex = 'checkout/SET_ACTIVE_CHECKOUT_STEP_INDEX',
  setIsFetchingPaymentMethods = 'checkout/IS_FETCHING_PAYMENT_METHODS',
  setHasPaymentMethodFetchFailed = 'checkout/HAS_PAYMENT_METHOD_FETCH_FAILED',
  setPaymentMethodsFetchedWithSum = 'checkout/PAYMENT_METHODS_FETCHED_WITH_SUM',
  setComment = 'checkout/SET_COMMENT',
  setBillingAddress = 'checkout/SET_BILLING_ADDRESS',
  setShippingAddress = 'checkout/SET_SHIPPING_ADDRESS',
  setCustomerInfo = 'checkout/SET_CUSTOMER_INFO'
}

export enum CheckoutGetters {
  getSelectedDeliveryMethod = 'checkout/getSelectedDeliveryMethod',
  getGroupedDeliveryMethodsForCart = 'checkout/getGroupedDeliveryMethodsForCart',
  getGroupOfSelectedDeliveryMethod = 'checkout/getGroupOfSelectedDeliveryMethod',
  getSelectedPaymentMethod = 'checkout/getSelectedPaymentMethod',
  getGroupedPaymentMethods = 'checkout/getGroupedPaymentMethods',
  getGroupOfSelectedPaymentMethod = 'checkout/getGroupOfSelectedPaymentMethod',
  getDeliveryMethodsForCart = 'checkout/getDeliveryMethodsForCart',
  getAvailablePaymentMethods = 'checkout/getAvailablePaymentMethods',
  getActiveCheckoutStepIndex = 'checkout/getActiveCheckoutStepIndex',
  hasFetchingPaymentMethodsFailed = 'checkout/hasFetchingPaymentMethodsFailed',
  isFetchingPaymentMethods = 'checkout/isFetchingPaymentMethods',
  getPaymentMethodsFetchedWithSum = 'checkout/getPaymentMethodsFetchedWithSum',
  getAddresses = 'checkout/getAddresses',
  getUser = 'checkout/getUser',
  hasSeparateShippingAddress = 'checkout/hasSeparateShippingAddress',
  saveAsDefault = 'checkout/saveAsDefault',
  getComment = 'checkout/getComment',
  getGrandTotal = 'checkout/getGrandTotal',
  getPartPaymentPlanForCart = 'checkout/getPartPaymentPlanForCart',
  getGiftCardAmountToUse = 'checkout/getGiftCardAmountToUse',
  getGrandTotalBeforeGiftCard = 'checkout/getGrandTotalBeforeGiftCard',
  getPartPaymentPlansSortedByContractLength = 'checkout/getPartPaymentPlansSortedByContractLength',
  getPartPaymentPlanSortedByMonthlyAnnuity = 'checkout/getPartPaymentPlanSortedByMonthlyAnnuity',
  getCampaignPartPaymentPlans = 'checkout/getCampaignPartPaymentPlans',
  getLongestCampaignPartPaymentPlan = 'checkout/getLongestCampaignPartPaymentPlan',
  getCheapestMonthlyAnnuityPaymentPlan = 'checkout/getCheapestMonthlyAnnuityPaymentPlan',
  getDeliveryFee = 'checkout/getDeliveryFee',
  getPartialDelivery = 'checkout/getPartialDelivery',
  getSubscribeNewsletter = 'checkout/getSubscribeNewsletter'
}

export const actions: ActionTree<CheckoutState, RootState> = {
  subscribeNewsletter ({ commit }, payload: boolean) {
    commit('SET_SUBSCRIBE_NEWSLETTER', payload)
  },
  clearSelectedDeliveryMethod ({ commit }) {
    commit('SET_SELECTED_DELIVERY_METHOD', null)
  },
  clearSelectedPaymentMethod ({ commit }) {
    commit('SET_SELECTED_PAYMENT_METHOD', null)
  },
  clearComment ({ commit }) {
    commit('SET_COMMENT', '')
  },
  selectPaymentMethod ({ commit }, payload: PaymentMethod) {
    commit('SET_SELECTED_PAYMENT_METHOD', payload)
  },
  selectDeliveryMethod ({ commit }, payload: DeliveryMethod) {
    commit('SET_SELECTED_DELIVERY_METHOD', payload)
  },
  activateStep ({ commit }, payload: CheckoutStepIndex) {
    commit('SET_ACTIVE_CHECKOUT_STEP_INDEX', payload)
  },
  async fetchPaymentMethods ({ commit, getters }) {
    commit('HAS_PAYMENT_METHOD_FETCH_FAILED', false)
    const grandTotal = getters.getGrandTotal
    let availablePaymentMethods: PaymentMethod[] = []
    if (getters.getPaymentMethodsFetchedWithSum === grandTotal) {
      return
    } else if (grandTotal === 0) {
      availablePaymentMethods.push({
        displayName: 'Lahjakortti',
        code: 'zeroPayment',
        imageUrl: ''
      })
    } else {
      commit('IS_FETCHING_PAYMENT_METHODS', true)
      // @ts-ignore
      const sveaPaymentMethods = await this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-providers-svea-getPaymentMethodsV2?amount=' + grandTotal.toFixed(2))
        .catch(() => {
          commit('HAS_PAYMENT_METHOD_FETCH_FAILED', true)
          return { data: [] }
        })
      if (sveaPaymentMethods.data.length) {
        commit('PAYMENT_METHODS_FETCHED_WITH_SUM', grandTotal)
      }
      availablePaymentMethods = [
        ...sveaPaymentMethods.data,
        {
          displayName: 'Klarna',
          code: 'KLARNA',
          imageUrl: '/assets/images/checkout/payment-icons/klarna.png'
        }
      ]
    }
    commit('SET_PAYMENT_METHODS', availablePaymentMethods)
    commit('IS_FETCHING_PAYMENT_METHODS', false)
  },
  async fetchPartPaymentPlanForAmount ({ commit }, payload: number) {
    commit('SET_PART_PAYMENT_PLAN', null)
    // @ts-ignore TODO add error handling
    const partPaymentPlan = await this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-providers-svea-fetchPartPaymentPlanV2?amount=' + payload)
    commit('SET_PART_PAYMENT_PLAN', partPaymentPlan.data)
  },
  setAddressData ({ commit }, payload: { type: 'billing' | 'shipping', key: string, value: any }) {
    commit('UPDATE_ADDRESS', payload)
  },
  hasSeparateShippingAddress ({ commit }, payload: boolean) {
    commit('HAS_SEPARATE_SHIPPING_ADDRESS', payload)
  },
  saveAsDefault ({ commit }, payload: boolean) {
    commit('SAVE_AS_DEFAULT', payload)
  },
  validateSelectedPaymentMethod ({ state, dispatch }) {
    if (state.selectedPaymentMethod && !state.availablePaymentMethods.some((pm: PaymentMethod) => pm.code === state.selectedPaymentMethod?.code)) {
      dispatch('clearSelectedPaymentMethod')
    }
  },
  validateSelectedDeliveryMethod ({ state, getters, dispatch }) {
    if (state.selectedDeliveryMethod && !getters.getDeliveryMethodsForCart.includes(state.selectedDeliveryMethod)) {
      dispatch('clearSelectedDeliveryMethod')
    }
  },
  selectOnlyPaymentMethod ({ state, dispatch }) {
    if (state.availablePaymentMethods.length === 1) {
      dispatch('selectPaymentMethod', state.availablePaymentMethods[0])
    }
  },
  saveCustomerInfo ({ commit }, payload: CustomerInfo) {
    commit('SET_CUSTOMER_INFO', payload)
  },
  prefillUserAddresses ({ state, rootState, commit }) {
    // @ts-ignore
    if (rootState.user.loggedIn) {
      // @ts-ignore
      const defaultBillingAddress = rootState.user?.details?.addresses?.find((address: UserAddress) => address.defaultBilling)
      if (defaultBillingAddress && (JSON.stringify(state.addresses?.billing) === JSON.stringify(getClearAddress()))) {
        const mappedBillingAddress: CartAddress = {
          // @ts-ignore
          firstname: rootState.user.details.firstname,
          // @ts-ignore
          lastname: rootState.user.details.lastname,
          phone: defaultBillingAddress.phone,
          street1: defaultBillingAddress.street,
          postcode: defaultBillingAddress.postcode,
          city: defaultBillingAddress.city,
          countryCode: defaultBillingAddress.countryCode
        }
        // @ts-ignore
        const defaultShippingAddress = rootState.user?.details?.addresses?.find((address: UserAddress) => address.defaultShipping)
        if (defaultShippingAddress && (JSON.stringify(state.addresses?.shipping) === JSON.stringify(getClearAddress()))) {
          const mappedShippingAddress: CartAddress = {
            // @ts-ignore
            firstname: rootState.user.details.firstname,
            // @ts-ignore
            lastname: rootState.user.details.lastname,
            phone: defaultShippingAddress.phone,
            street1: defaultShippingAddress.street,
            postcode: defaultShippingAddress.postcode,
            city: defaultShippingAddress.city,
            countryCode: defaultShippingAddress.countryCode
          }
          commit('SET_SHIPPING_ADDRESS', mappedShippingAddress)
        }
        commit('SET_BILLING_ADDRESS', mappedBillingAddress)
        // @ts-ignore
        commit('UPDATE_USER_DATA', { key: 'email', value: rootState.user?.user?.email || '' })
      }
    }
  },
  async payOrder ({ state, rootState, rootGetters }) {
    // @ts-ignore get user id if user is logged in
    const uid = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.uid : null
    // @ts-ignore
    const email = rootState.user.loggedIn && rootState.user.user ? rootState.user.user.email : null
    // @ts-ignore get cart items
    const items = rootGetters[CartGetters.items]
    // Get shippings
    const selectedShippingMethod = { ...state.selectedDeliveryMethod! }
    // @ts-ignore
    const campaign = rootState.cart.campaign
    // @ts-ignore
    const giftCard = rootState.cart.giftCard
    // @ts-ignore
    const freeItem = rootState.cart.freeItem
    const cart: PaymentCart = {
      items,
      selectedShippingMethod,
      uid,
      email,
      comment: state.comment,
      giftCard,
      selectedPaymentMethod: state.selectedPaymentMethod!,
      selectedPaymentProvider: state.selectedPaymentMethod!.code === 'KLARNA' ? 'klarna' : 'svea',
      user: state.user,
      addresses: {
        billing: state.addresses.billing,
        shipping: state.addresses.shipping
      },
      freeItem,
      partialDelivery: state.partialDelivery,
      subscribeNewsletter: state.subscribeNewsletter || false
    }
    if (campaign) {
      cart.campaign = {
        code: campaign.code
      }
    }

    const authorization = await this.$fire.auth.currentUser?.getIdToken()
    // @ts-ignore
    return this.$axios.post(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-payOrderV2', { cart }, { headers: { authorization } })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  },
  setPartialDelivery ({ commit }, payload: boolean) {
    commit('SET_PARTIAL_DELIVERY', payload)
  },
  clearCheckout ({ commit }) {
    commit('CLEAR_CHECKOUT')
  }
}

export const mutations: MutationTree<CheckoutState> = {
  SET_SELECTED_DELIVERY_METHOD (state, payload: DeliveryMethod | null) {
    state.selectedDeliveryMethod = payload
  },
  SET_SELECTED_PAYMENT_METHOD (state, payload: PaymentMethod | null) {
    state.selectedPaymentMethod = payload
  },
  SET_PAYMENT_METHODS (state, payload: PaymentMethod[]) {
    state.availablePaymentMethods = payload
  },
  SET_ACTIVE_CHECKOUT_STEP_INDEX (state, payload: CheckoutStepIndex) {
    state.activeCheckoutStepIndex = payload
  },
  IS_FETCHING_PAYMENT_METHODS (state, payload: boolean) {
    state.isFetchingPaymentMethods = payload
  },
  HAS_PAYMENT_METHOD_FETCH_FAILED (state, payload: boolean) {
    state.hasPaymentMethodFetchFailed = payload
  },
  PAYMENT_METHODS_FETCHED_WITH_SUM (state, payload: number) {
    state.paymentMethodsFetchedWithSum = payload
  },
  UPDATE_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses[payload.type][payload.key] = payload.value
  },
  SET_BILLING_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses.billing = payload
  },
  SET_SHIPPING_ADDRESS (state, payload) {
    // @ts-ignore
    state.addresses.shipping = payload
  },
  SAVE_AS_DEFAULT (state, payload) {
    // @ts-ignore
    state.saveAsDefault = payload
  },
  UPDATE_USER_DATA (state, payload) {
    // @ts-ignore
    state.user[payload.key] = payload.value
  },
  HAS_SEPARATE_SHIPPING_ADDRESS (state, payload: boolean) {
    // clear shipping address if user wants to give a different shipping address
    if (payload) {
      state.addresses.shipping = getClearAddress()
    }
    state.hasSeparateShippingAddress = payload
  },
  SET_COMMENT (state, payload: string) {
    state.comment = payload
  },
  CLEAR_CHECKOUT (state) {
    Object.assign(state, getClearState())
  },
  SET_PART_PAYMENT_PLAN (state, payload: any) {
    state.partPaymentPlan = payload
  },
  SET_CUSTOMER_INFO (state, payload: CustomerInfo) {
    state.addresses = payload.addresses
    state.user = payload.user
    state.hasSeparateShippingAddress = payload.hasSeparateShippingAddress
    if (!payload.hasSeparateShippingAddress) {
      state.addresses.shipping = { ...state.addresses.billing }
    }
  },
  SET_PARTIAL_DELIVERY (state, payload: boolean) {
    state.partialDelivery = payload
  },
  SET_SUBSCRIBE_NEWSLETTER (state, payload: boolean) {
    state.subscribeNewsletter = payload
  }
}

export const getters: GetterTree<CheckoutState, RootState> = {
  getSelectedDeliveryMethod: state => state.selectedDeliveryMethod,
  extendDeliveryMethodGroup: (_state, getters) => (group: DeliveryMethodGroup): DeliveryMethodGroupWithMethods|null => {
    const deliveryMethods = getters.getDeliveryMethodsForCart.filter((dm: DeliveryMethod) => group.id === dm.group)
    if (!deliveryMethods.length) { return null }
    const lowestPrice = deliveryMethods.reduce((prev: DeliveryMethod, curr: DeliveryMethod) => prev.price < curr.price ? prev : curr).price
    const highestPrice = deliveryMethods.reduce((prev: DeliveryMethod, curr: DeliveryMethod) => prev.price > curr.price ? prev : curr).price
    return (
      {
        ...group,
        deliveryMethods,
        lowestPrice,
        highestPrice
      })
  },
  getGroupedDeliveryMethodsForCart: (state, getters): DeliveryMethodGroupWithMethods[] => state.allDeliveryMethodGroups.map((dmg: DeliveryMethodGroup) => getters.extendDeliveryMethodGroup(dmg)).filter(Boolean),
  getSelectedPaymentMethod: (state): PaymentMethod | null => state.selectedPaymentMethod,
  getGroupedPaymentMethods: state => state.allPaymentMethodGroups
    .map((pmg: PaymentMethodGroup) => (
      {
        ...pmg,
        paymentMethods: state.availablePaymentMethods.slice().filter((pm: PaymentMethod) => pmg.paymentMethodIds.includes(pm.code)).sort((a: PaymentMethod, b: PaymentMethod) => a.displayName.localeCompare(b.displayName, 'fi'))
      }))
    .filter((pmg: PaymentMethodGroupWithOptions) => pmg.paymentMethods.length),
  getGroupOfSelectedDeliveryMethod: (state): DeliveryMethodGroup | null => state.allDeliveryMethodGroups?.find(dmg => dmg.id === state.selectedDeliveryMethod?.group) || null,
  getGroupOfSelectedPaymentMethod: (state): PaymentMethodGroup | null => state.allPaymentMethodGroups.find(pmg => pmg.paymentMethodIds.includes(state.selectedPaymentMethod?.code || '')) || null,
  getDeliveryMethodsForCart: (_state, _getters, rootState): DeliveryMethod[] => {
    const { getCartMaxShippingPrices } = useShipping()
    // @ts-ignore
    const deliveryMethodsFromCartItems = getCartMaxShippingPrices(rootState.cart.items)
    // DEPRECATED already? map: Old carts do not have 'group' property in delivery methods. Temporary solution is to read the full object from state.
    return deliveryMethodsFromCartItems
      // .map((cdm: DeliveryMethod) => (state.allDeliveryMethods.find((adm: DeliveryMethod) => cdm.id === adm.id)) || cdm)
      .filter((dm): dm is DeliveryMethod => dm !== undefined)
      .sort((a: DeliveryMethod, b: DeliveryMethod) => a.price > b.price ? 1 : -1)
  },
  isFetchingPaymentMethods: state => state.isFetchingPaymentMethods,
  hasFetchingPaymentMethodsFailed: state => state.hasPaymentMethodFetchFailed,
  getPaymentMethodsFetchedWithSum: state => state.paymentMethodsFetchedWithSum,
  getAvailablePaymentMethods: state => state.availablePaymentMethods,
  getActiveCheckoutStepIndex: state => state.activeCheckoutStepIndex,
  getAddresses: state => state.addresses,
  getUser: state => state.user,
  hasSeparateShippingAddress: state => state.hasSeparateShippingAddress,
  saveAsDefault: state => state.saveAsDefault,
  getComment: state => state.comment,
  getGrandTotalBeforeGiftCard: (state, _getters, _rootState, rootGetters): number => {
    const cartTotal = rootGetters[CartGetters.total] || 0
    const deliveryFee = state.selectedDeliveryMethod?.price || 0
    const couponDiscount = rootGetters[CartGetters.campaignDiscountAmount] || 0
    return cartTotal + deliveryFee - couponDiscount
  },
  getGiftCardAmountToUse: (_state, getters, _rootState, rootGetters): number => {
    const giftCardOpenAmount = rootGetters[CartGetters.giftCard]?.openAmount || 0
    const grandTotalBeforeGiftCard = getters.getGrandTotalBeforeGiftCard
    return Math.min(giftCardOpenAmount, grandTotalBeforeGiftCard)
  },
  getGrandTotal: (_state, getters): number => getters.getGrandTotalBeforeGiftCard - getters.getGiftCardAmountToUse,
  getPartPaymentPlanForCart: state => state.partPaymentPlan,
  getPartPaymentPlansSortedByContractLength: state => state.partPaymentPlan?.campaigns?.slice().sort((a: PartPaymentPlanCampaign, b: PartPaymentPlanCampaign) => a.ContractLengthInMonths - b.ContractLengthInMonths) || [],
  getPartPaymentPlanSortedByMonthlyAnnuity: state => state.partPaymentPlan?.campaigns?.slice().sort((a: PartPaymentPlanCampaign, b: PartPaymentPlanCampaign) => a.MonthlyAnnuity - b.MonthlyAnnuity) || [],
  getCheapestMonthlyAnnuityPaymentPlan: (_state, getters): PartPaymentPlanCampaign | null => {
    return getters.getPartPaymentPlanSortedByMonthlyAnnuity.length ? getters.getPartPaymentPlanSortedByMonthlyAnnuity[0] : null
  },
  getCampaignPartPaymentPlans: (state): PartPaymentPlanCampaign[] => state.partPaymentPlan?.campaigns?.filter((ppp: PartPaymentPlanCampaign) => ppp.NrOfInterestFreeMonths === ppp.ContractLengthInMonths && ppp.ContractLengthInMonths > 3) || [],
  getLongestCampaignPartPaymentPlan: (_state, getters): PartPaymentPlanCampaign | null => getters.getCampaignPartPaymentPlans.length > 0 ? getters.getCampaignPartPaymentPlans.reduce((currentHighest: PartPaymentPlanCampaign, partPayment: PartPaymentPlanCampaign) => currentHighest.ContractLengthInMonths > partPayment.ContractLengthInMonths ? currentHighest : partPayment) : null,
  getPartialDelivery: (state): boolean => state.partialDelivery,
  getSubscribeNewsletter: (state): boolean => state.subscribeNewsletter
}
