import { Store } from 'vuex'
import { CategoryGetters } from '~/store/category'
import type {
  AttributeValueObject,
  GtmItemListData,
  GtmItemListProduct,
  GtmProduct,
  ProductAlgolia,
  ProductCategory,
  ProductTileAlgolia
} from '~/types/product'
import type { Breadcrumb } from '~/types/breadcrumb'
import type { CartItem } from '~/types/item'
import type { CategoryItem } from '~/types/category'
import type { MatomoEcommView, MatomoItem } from '~/modules/matomo/types'
import { productCampaingSegments } from '~/config/label-parsers'

export function useProduct () {
  const getImageTypesString = (product: ProductAlgolia | ProductTileAlgolia): string => {
    const segments: string[] = []

    if (product.images.some(img => !img.isMilleuImage)) {
      segments.push('PHOTO')
    }
    if (product.images.some(img => img.isMilleuImage)) {
      segments.push('MILIEU')
    }
    if (product.pixmoorUrl !== '') {
      segments.push('360')
    }
    if (product.reviewImages?.length > 0) {
      segments.push('USER_PHOTO')
    }
    if (product.video) {
      segments.push('VIDEO')
    }
    return segments.join(',')
  }

  const getSegmentLabels = (product: ProductAlgolia | ProductTileAlgolia): string => {
    const segmentLabels: string[] = []

    // Add 'TARJOUS' if the product is on sale
    if (product.finalPrice.isOnSale) {
      segmentLabels.push('TARJOUS')
    }

    // Process product.labels if they exist
    if (product.labels) {
      const matchingLabels = product.labels
        .flatMap(label =>
          productCampaingSegments.includes(label.text.toLowerCase())
            ? label.text.toUpperCase()
            : []
        )
      segmentLabels.push(...matchingLabels) // Add matching labels to the array
    }

    // Return the result or 'NO_SEGMENT' if no labels exist
    return segmentLabels.length > 0 ? segmentLabels.join(',') : 'NO_SEGMENT'
  }
  const convertCartItemsToMatomoItems = (items: CartItem[]): MatomoItem [] => {
    return items.map((item: CartItem) => {
      return {
        sku: item.sku,
        name: item.variationName,
        categories: item.categories,
        price: item.price.final,
        amount: item.amount
      }
    })
  }

  const mapMatomoProductViewData = (product: ProductAlgolia, breadcrumbs: Breadcrumb[]): MatomoEcommView => {
    return {
      sku: product.sku,
      name: product.name?.variation,
      categories: breadcrumbs.map(cat => cat.value).join(' / '),
      price: product.customization?.finalPrice || product.finalPrice?.inclTax
    }
  }

  const convertProductToGtmProduct = (product: ProductAlgolia, store: any): GtmProduct | null => {
    if (!product) { return null }
    return {
      name: product.name.default, // OR product.name.variant
      id: product.parentSku, // parent SKU
      variant: product.sku, // child SKU
      brand: getBrandList(product.marketing.productManufacturer), // Product brand list
      price: product.finalPrice.inclTax, // final price, after discounts
      category: getCategoryPath(product.categories, store).map(cat => cat.value).join('/'),
      stock_status: product.stock, // 'status=in_stock,verkkokauppa=0,rovaniemi=1,vantaa=12,oulu=4,delivery_time=30,delivery_cost=19.90', // stock_status
      campaign_info: product.price.inclTax !== product.finalPrice.inclTax
        ? {
            on_sale: true,
            original_price: product.price.inclTax,
            campaign_code: product.finalPrice.ruleId
          }
        : undefined, // 'on_sale=true,original_price=22.00,campaign_code=KESÄ20', // campaign_info
      rating_info: product.yotpo, // 'rating_count=10,rating_value=3.5,question_count=3,ugc_post_count=3' // product_ratings & ugc_posts,
      product_campaign_segment: getSegmentLabels(product), // 'TARJOUS,SUPERTARJOUS,OUTLET' // product_campaign_segment
      product_media_type: getImageTypesString(product) // 'PHOTO,MILIEU,360,USER_PHOTO,VIDEO' // product_image_segment
    }
  }

  const convertCartItemToGtmProduct = (cartItem: CartItem): GtmProduct | null => {
    if (!cartItem) { return null }

    return {
      name: cartItem.title,
      id: cartItem.parentSku,
      variant: cartItem.sku,
      brand: getBrandList(cartItem.brands),
      price: cartItem.price.final,
      quantity: cartItem.amount,
      category: cartItem.categories,
      stock_status: cartItem.stock,
      campaign_info: cartItem.price.original !== cartItem.price.final
        ? {
            on_sale: true,
            original_price: cartItem.price.original,
            campaign_code: cartItem.price.priceRuleId
          }
        : undefined,
      rating_info: cartItem.reviews,
      product_campaign_segment: cartItem.productCampaignSegment,
      product_media_type: cartItem.productMediaType
    }
  }

  const getBrandList = (productManufacturer: AttributeValueObject | undefined): string => {
    if (typeof productManufacturer?.value === 'object') {
      return productManufacturer?.value.map((brand: AttributeValueObject) => brand.label).join(', ')
    }
    return ''
  }

  const getCategoryPath = (categories: ProductCategory[], store: any): Breadcrumb[] => {
    const result: Breadcrumb[] = []
    let currLvl: number = 0
    let currParentId: string = ''

    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i]

      if (cat.level === currLvl + 1) {
        // Root category
        if (cat.level === 1) {
          if (cat.id === '14' || cat.id === '2964') {
            // skip 'Ideat' and 'Feikki kategoriat'
            continue
          }

          const categoryItem: CategoryItem = store.getters[CategoryGetters.getCategoryById](cat.id)
          if (categoryItem && categoryItem.path) {
            currLvl = cat.level
            currParentId = cat.id
            // @TODO: Add Sentry logging
            result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            continue
          }
        }

        // Children
        if (cat.parentId === currParentId) {
          const parent = categories.find(c => c.id === currParentId)

          if (parent) {
            const categoryItem: CategoryItem = store.getters[CategoryGetters.getCategoryById](cat.id)
            if (categoryItem && categoryItem.path) {
              currLvl = cat.level
              currParentId = cat.id
              // @TODO: Add Sentry logging
              result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            }
          }
        }
      }
    }

    return result
  }

  const getAvailableAmount = (product: ProductAlgolia, amount: number): number => {
    // Discontinued product max buy amount
    if (product.productLine === 2) {
      return amount > product.stock.qty ? product.stock.qty : amount
    }

    return amount
  }

  const categoyListItemsForGtm = (products: ProductAlgolia[] | ProductTileAlgolia[], store: Store<any>, itemListData: GtmItemListData): GtmItemListProduct[] => {
    const parsedProductData: GtmItemListProduct[] = []
    products.forEach((algoliaProduct, i) => {
      const parsedCategories = getCategoryPath(algoliaProduct.categories, store)
      const item: GtmItemListProduct = {
        item_id: algoliaProduct.parentSku,
        // @ts-ignore
        item_brand: algoliaProduct.marketing && algoliaProduct.marketing.productManufacturer ? algoliaProduct.marketing.productManufacturer.value[0].label : 'N/A',
        item_variant: algoliaProduct.sku,
        item_list_id: itemListData.itemListId,
        index: i,
        item_name: algoliaProduct.name.default,
        item_list_name: itemListData.itemListName,
        price: algoliaProduct.finalPrice.inclTax,
        discount: parseFloat((algoliaProduct.finalPrice.isOnSale ? algoliaProduct.price.inclTax - algoliaProduct.finalPrice.inclTax : 0).toFixed(2)),
        product_campaign_segment: getSegmentLabels(algoliaProduct), // 'TARJOUS,SUPERTARJOUS,OUTLET' // product_campaign_segment
        product_media_type: getImageTypesString(algoliaProduct) // 'PHOTO,MILIEU,360,USER_PHOTO,VIDEO' // product_image_segment
      }
      // loop to assign categories dynamically
      for (let j = 0; j < 5; j++) {
        if (parsedCategories[j]?.value) {
          item[`item_category${j === 0 ? '' : j + 1}`] = parsedCategories[j].value
        }
      }
      parsedProductData.push(item)
    })
    // console.log(parsedProductData[0])
    return parsedProductData
  }

  const categoyListItemsForGtmFromCartItems = (products: CartItem[], store: Store<any>, itemListData: GtmItemListData): GtmItemListProduct[] => {
    const parsedProductData: GtmItemListProduct[] = []
    products.forEach((cartItem, i) => {
      // @ts-ignore
      const parsedCategories = getCategoryPath(cartItem.productCategories, store)
      const item: GtmItemListProduct = {
        item_id: cartItem.parentSku,
        item_brand: cartItem.brands ? cartItem.brands.value[0].label : '',
        item_variant: cartItem.sku, // why sku here as double?
        item_list_id: itemListData.itemListId,
        index: i,
        item_name: cartItem.title,
        item_list_name: itemListData.itemListName,
        price: cartItem.price.final,
        discount: parseFloat((cartItem.price.salePercent ? cartItem.price.original - cartItem.price.final : 0).toFixed(2)),
        quantity: cartItem.amount
      }
      // loop to assign categories dynamically
      for (let j = 0; j < 5; j++) {
        if (parsedCategories[j]?.value) {
          item[`item_category${j === 0 ? '' : j + 1}`] = parsedCategories[j].value
        }
      }
      parsedProductData.push(item)
    })
    return parsedProductData
  }

  return {
    categoyListItemsForGtm,
    convertCartItemsToMatomoItems,
    mapMatomoProductViewData,
    convertProductToGtmProduct,
    convertCartItemToGtmProduct,
    getBrandList,
    getCategoryPath,
    getAvailableAmount,
    categoyListItemsForGtmFromCartItems,
    getImageTypesString,
    getSegmentLabels
  }
}
